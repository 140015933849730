<template>
    <div class="xtPt" style="padding: 0 2.6rem;">
        <div class="xtPt_l" @click="goadmin">
            <div class="xtPt_l_t">
                <img src="@/assets/home/xnzhzxxxhpt.png" alt="">
            </div>
            <div class="xtPt_l_b">
                <img src="@/assets/home/tianGuanli.png" alt="">
                <img src="@/assets/home/weiyuanLvzhi.png" alt="">
                <img src="@/assets/home/sheqingMinyi.png" alt="">

            </div>
        </div>
        <div class="block">

        </div>
        <div class="xtPt_r" @click="goyun">
            <img src="@/assets/home/phone.png" alt="" style="cursor: pointer;">
        </div>

    </div>
</template>
  
<script>
export default {
	data() {
		return{
			
		}
	},
	methods:{
		goyun(){
			this.$router.push('/secondList?id=99&name=云端委员驿站联盟')
		},
		goadmin(){
			this.$router.push('/login')
		}
	}
}
</script>
  
<style scoped lang="scss">
.xtPt {
    margin-top: .31rem;
    display: flex;

    .xtPt_l {
        display: flex;
        width: 7.50rem;
        flex-direction: column;
		
        .xtPt_l_t img {
            display: block;
            width: 100%;
			cursor: pointer;
        }

        .xtPt_l_b {
            width: 100%;
            display: flex;
            margin-top:.08rem;
            justify-content: space-between;

            img {
                display: block;
                width: 2.40rem;
                // height: ;
				cursor: pointer;
            }
        }
    }

    .block {
        width: 0.07rem;
        background: #E1EEFD;
        margin: 0 0.14rem;
    }
    .xtPt_r{
        flex: 1;
        display: flex;
        justify-content: space-between;
        img{
            display: block;
            width: 100%;
        }
    }
}
</style>