<template>
	<div style="width: 100%;padding: 0 2.6rem;">
		<div class="zhuanti_zhuanlan">
			<div class="title">
				<div class="flex-aic-c">
					<div class="color-fff font23 text" style="writing-mode: vertical-rl;letter-spacing: 8px;">
						{{name}}
					</div>
				</div>
				<div class="block1"></div>
				<div class="block1">

				</div>
			</div>
			<div class="img_group">
				<div class="swiper-container" ref="swiperContainer">
					<div class="swiper-wrapper">
						<div class="swiper-slide font19 swiperimgbox" v-for="(item,index) in list" :key="index"
							@click="godetail(item)">
							<img :src="item.slturl[0]?item.slturl[0].fullurl:''" alt="">
							<!-- <img :src="item.url?item.url:''" alt=""> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import 'swiper/swiper.scss'
	import Swiper, {
		Autoplay
	} from 'swiper'
	Swiper.use([Autoplay])
	export default {
		data() {
			return {
				name: '',
				list: [],
				swiper: '',
			}
		},
		created() {
			this.$post({
				url: '/api/classify/index',
				params: {
					type: 1,
					p: 1,
					size: 9999
				}
			}).then((res) => {
				res.list.forEach((item, index) => {
					if (item.id == 7) {
						this.name = item.name
					}
				})
			})

			this.$post({//获取数据
				url: '/api/ad/index',
				params: {
					adtype_id:8,
					p: 1,
					size: 99
				}
			}).then((res) => {
				console.log(res.list)
				this.list = res.list.concat(res.list).concat(res.list)
				this.$nextTick(() => {
				    this.initSwiper();
				})
			})
		},
		mounted() {
			
		},
		methods: {
			godetail(item) {
				if (item.tzurl.includes('http')) {
					window.open(item.tzurl, "_blank")
				} else {
					this.$router.push(item.tzurl)
				}
			},
			// 初始化轮播
			initSwiper() {
				this.swiper = new Swiper(this.$refs.swiperContainer, {
					slidesPerView: 3,
					spaceBetween: 20,
					loop: true,
					observer: true,
					speed: 1000,
					autoplay: {
					    delay: 5000,
					    stopOnLastSlide: false,
					    disableOnInteraction: true,
					},
					navigation: {
					    nextEl: '.swiper-button-next',
					    prevEl: '.swiper-button-prev',
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.swiper-container {
		height: 1.6rem;
		width: 11rem;
	}

	.swiperimgbox {
		cursor: pointer;

		.text_show {
			display: none !important;
		}
	}

	.swiperimgbox {
		display: flex !important;
	}

	.zhuanti_zhuanlan {
		display: flex;
		border: 0.01rem solid #E1E1E1;

		.title {
			display: flex;
			width: 0.63rem;
			flex-direction: column;
			margin-right: .26rem;

			div {
				background: #3D7ED0;
			}

			.text {
				//  writing-mode: vertical-rl;
				//  /* 设置文字竖向排列，从上到下 */
				//  text-orientation: upright;
				//  /* 设置文字方向为正常，不倾斜 */
				padding: 0.19rem 0 .15rem 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;

				p {
					margin-bottom: .10rem;
				}
			}

			.block1 {
				height: .04rem;
				margin: 0.02rem 0;
			}

			.block2 {
				height: 0.07rem;
			}
		}

		.img_group {
			display: flex;
			flex: 1;
			justify-content: space-between;
			align-items: center;
			padding: 0px .24rem 0rem 0;

			img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}
</style>