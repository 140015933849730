<template>
    <div>
        <div class="head-line bx">
			<div style="height: 5.6rem;width: 100%;">
				<zxDtGk/>
			</div>
            
            <xtPt />
            <contentGroup />
            <zhuantiZhuanlan />
        </div>
        <div class="weiyuanLvzhiBg">
            <div class="bx" style="position: relative;z-index: 5;">
                <weiyuanLvzhi />
            </div>
			<img src="../../../assets/home/weiyuanLvzhiBg.png" alt="" style="position: absolute;left: -5vw;top: 0;width: 100vw;height: 100%;"/>
        </div>

        <div class="bx" style="padding: 0 2.6rem;">
            <imgNews />
        </div>
     
    </div>
</template>

<script>
import zxDtGk from './zxDtGk.vue'
import xtPt from './xtPt.vue'
import contentGroup from './contentGroup.vue'
import zhuantiZhuanlan from "./zhuantiZhuanlan.vue"
import weiyuanLvzhi from "./weiyuanLvzhi.vue"
import imgNews from "./imgNews.vue"

export default {
    components: {
        zxDtGk,
        xtPt,
        contentGroup,
        zhuantiZhuanlan,
        weiyuanLvzhi,
        imgNews,
        // copyRight
    },
}
</script>

<style scoped lang="scss">
.weiyuanLvzhiBg {
	margin-top: 20px;
	position: relative;
	padding: 0 2.6rem;
    // background: url('../../../assets/home/weiyuanLvzhiBg.png') no-repeat;
    // background-size: 100% 100%;
}


</style>