<template>
    <div class="zx_dt_gk" style="height: 5.6rem;padding: 0 2.6rem;">
        <div class="dt" style="display: flex;flex-direction: column;height: 5.6rem;">
            <titlePic :parameter="{ 'text': name, 'link': '/secondList?id=19&name='+name,'bg':'#EDEDED' }" />
            <div class="title_bg">
                <div class="titleText" style="cursor: pointer;" @click="goxq(listData[0])">
					<div class="over2esp">
						{{listData[0].name}}
					</div>
				</div>
            </div>
            <div class="content" style="flex: 1;">
                <div class="cont_l">
                    <el-carousel trigger="click" height="3.5rem">
                        <el-carousel-item v-for="(item, index) in bannerData" :key="index" :label="index + 1">
                            <img :src="item.slturl[0].fullurl" alt="" style="width:100%;height:100%;cursor: pointer;" @click="go(item.tzurl)">
                            <div class="banner_text font23">{{ item.name }}</div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="cont_r">
                    <div class="first" @click="goxq(listData[1])">
                        <div class="first_tit font21 fontweight500 text text-align-c" style="padding-bottom: 0.1rem;">
							<div class="over2esp">
								{{listData[1].name}}
							</div>
                            
                        </div>
                        <div class="first_cont fontweight500 font19 maxline over2esp" style="line-clamp:2;-webkit-line-clamp:2;">
                            {{listData[1].jianjie}}
                        </div>
                    </div>
                    <div class="line" style="margin-top: 0.1rem;"></div>
                    <ul class="cont_r_list">
                        <li v-for="(item, index) in listData" :key="index" v-if="index>1" @click="goxq(item)" class="color-33 font23 fontweight500">
                            <div class="dian"></div>
							<div style="width: 80%;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;font-size: 0.16rem;">
								{{ item.name }}
							</div>
                            
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <zxGaikuang />
    </div>
</template>
  
<script>
import titlePic from '@/components/title_pic.vue'
import zxGaikuang from './zxGaikuang.vue';
export default {
    components: {
        titlePic,
        zxGaikuang
    },
    data() {
        return {
            listData: [],
            bannerData: [],
			name:''
        }
    },
	created() {
		this.$post({
			url: '/api/classify/index',
			params: {
				type:1,
				p: 1,
				size: 9999
			}
		}).then((res) => {
			res.list.forEach((item,index)=>{//获取模块标题中文
				if(item.id == 1){
					this.name = item.name
				}
			})
		})
		
		this.$post({//获取政协要闻数据
			url: '/api/news/index',
			params: {
				id:19,
				p: 1,
				size: 8
			}
		}).then((res) => {
			this.listData = res.list
		})
		
		this.$post({//获取轮播图
			url: '/api/ad/index',
			params: {
				adtype_id:7,
				p: 1,
				size: 5
			}
		}).then((res) => {
			console.log(99999)
			console.log(res.list)
			this.bannerData = res.list
		})
		
	},
	methods:{
		goxq(item){
			if(item.tzurl){
				window.open(item.tzurl,"_blank")
				return
			}
			this.$router.push('/secondList?id=1&name=政协动态&detail_id='+item.id)
		},
		go(url){
			window.open(url,'_blank')
		}
	}
}
</script>
  
<style scoped lang="scss">
.zx_dt_gk {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dt {
    width: 75.4%;
	min-height: 100%;
    .title_bg {
        padding: .17rem .06rem;
        background: #F6F6F6;

        .titleText {
            padding: .16rem 0;
            text-align: center;
            font-size: .45rem;
            color: #2C65AF;
            font-weight: 500;
            background: #fff;
			
			
        }
		
    }

    .content {
        padding: .10rem .15rem .21rem .18rem;
        background: #FFFFFF;
        display: flex;
        justify-content: space-between;
        border: .01rem solid #E1E1E1;
        border-top: none;

        .cont_l {
            width: 5rem;
            flex-shrink: 0;

            .banner_text {
                text-align: center;
                width: 100%;
                padding: .17rem 0 .14rem 0;
                color: #fff;
                background: rgba(00, 00, 00, .75);
                position: absolute;
                bottom: 0;
            }

            .el-carousel {
                overflow: hidden;
            }



            .el-carousel__item h3 {
                color: #475669;
                font-size: .14rem;
                opacity: 0.75;
                line-height: 1.50rem;
                margin: 0;
            }

            .el-carousel__item:nth-child(2n) {
                background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n+1) {
                background-color: #d3dce6;
            }

            ::v-deep .el-carousel__indicators {
                // 指示器
                left: unset;
                transform: unset;
                right: 2%;
                height: 1.3rem;
            }

            ::v-deep .el-carousel__button {

                // 指示器按钮
                border: none;
                font-size: .15rem;
                width: .34rem;
                height: .40rem;
                background-color: #FFFFFF;
                color: #040504;
            }

            ::v-deep .is-active .el-carousel__button {
                // 指示器激活按钮
                background: #D93D1A;
                color: #FFFFFF;
            }

            ::v-deep .el-carousel__container {
                height: 100%;
            }
        }

        .cont_r {
            padding-left: .25rem;

            .first {
                .first_tit {
                    padding: .16rem 0 .30rem 0;
                    color: #252525;
                    line-height: .30rem;
                }
            }

            .first_cont {
                text-indent: 2em;
                color: #666;
                line-height: .34rem;
            }
			.first:hover{
				.first_tit,.first_cont{
					color: #2C65AF !important; 
				}
				
			}
            .line {
                border-bottom: .01rem dashed #E1E1E1;
                margin-top: .32rem;
            }

            .cont_r_list {
                margin-top: .21rem;

                li {
                    display: flex;
                    align-items: center;

                    .dian {
                        width: 4px;
                        height: 4px;
                        background: #2C65AF;
                        margin-right: .21rem;
                    }

                    margin-bottom: .11rem;
                    line-height: .32rem;
                }
            }
        }
    }

}
.maxline{
	overflow: hidden;
	-webkit-line-clamp: 4;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}
.fontweight500{
	cursor: pointer;
}
.fontweight500:hover{
	color: #2C65AF;
}
.over2esp{
			overflow:hidden;
			text-overflow:ellipsis;			
			line-clamp:1;
			display:-webkit-box;			
			text-overflow:-o-ellipsis-lastline;			
			-webkit-line-clamp:1;
			-webkit-box-orient:vertical;
		}
</style>