<template>
    <div class="weiyuan_lvzhi">
        <div class="title">
            <div class="title_l">
                <img src="@/assets/home/weiyuanLvzhiTitle.png" alt="">
            </div>
            <div class="title_r font23  flex-sb">
                <div class="nav_bar flex fontweight600 color-33">
                    <p :class="activeInd == 1 ? 'active' : ''" @click="handleTabr(1)">{{name1}}</p>
                    <p :class="activeInd == 2 ? 'active' : ''" @click="handleTabr(2)">{{name2}}</p>
                </div>
                <div class="more font19 color-44" style="cursor: pointer;" @click="gomore">更多 >></div>
            </div>
        </div>
        <div class="cont">
            <div v-if="activeInd == 1" class="weiyuanjianyan">
                <ul>
                    <li v-for="(item, index) in jianyanData" :key="index">
                        <div class="dian"></div>
                        <p class="color-53 font28 fontweight500" style="font-size: 0.18rem;" @click="godetail(item)">{{ item.name }}</p>
                    </li>
                </ul>
            </div>
            <div v-else class="weiyuanfengcai">
                <div class="swiper-container" ref="swiperContainer">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide font19 swiperimgbox" v-for="(item, index) in fengcaiData" :key="item.id" @click="godetail(item)"
                            @mouseenter="handleMouseEnter(index)" @mouseleave="mengcengInd = ''">
                            <img :src="item.slturl[0].fullurl" alt="">
                            <div class="item text_show">
                                <div class="Mc_name font28 color-fff " style="padding-left: 5px;">{{ item.name }}</div>
                                <div class="Mc_text ">
                                    <p class=" font24 color-fff fontweight400" style="font-size: 0.18rem;">{{ item.jianjie }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="btnActive=='l'?'swiper-button-prev act':'swiper-button-prev'" @click="handleBtn(1)" @mouseenter="btnActive='l'" @mouseleave="btnActive=''"><i class="el-icon-arrow-left"></i>
                </div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
                <div :class="btnActive=='r'?'swiper-button-next act':'swiper-button-next'" @click="handleBtn(2)" @mouseenter="btnActive='r'" @mouseleave="btnActive=''"><i class="el-icon-arrow-right"></i></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
            </div>
        </div>
    </div>
</template>
  
<script>
import 'swiper/swiper.scss'
import Swiper, { Autoplay } from 'swiper'
Swiper.use([Autoplay])
export default {
    data() {
        return {
            activeInd: 2,
            mengcengInd: '',
            swiper: '',
            btnActive:'',
            jianyanData: [],
            fengcaiData: [],
			name1:'',
			name2:''
        }
    },
	mounted() {
	    this.$post({
	    	url: '/api/classify/index',
	    	params: {				
	    		p: 1,
	    		size: 9999
	    	}
	    }).then((res) => {
	    	res.list.forEach((item,index)=>{//获取模块标题中文
	    		if(item.id == 11){//委员建言
	    			this.name1 = item.name
	    			this.$post({//获取数据
	    				url: '/api/news/index',
	    				params: {
	    					id:22,
	    					p: 1,
	    					size: 10
	    				}
	    			}).then((res) => {
	    				this.jianyanData = res.list
	    			})
	    		}
	    	})
	    })
		
		this.$post({//获取数据
			url: '/api/news/index',
			params: {
				id:21,
				p: 1,
				size: 10
			}
		}).then((res) => {
			this.fengcaiData = res.list
			this.name2 = res.list[0].classify_name
			this.$nextTick(() => {
			    this.initSwiper();
			})
		})
	},
    methods: {
		gomore(){
			if(this.activeInd == 1){
				this.$router.push('/secondList?id=22&name=走进委员>委员建言')
			}else{
				this.$router.push('/secondList?id=21&name=走进委员>委员风采')
			}
		},
		godetail(item){
			if(item.tzurl){
				window.open(item.tzurl,"_blank")
				return
			}
			if(this.activeInd == 1){
				this.$router.push('/secondList?id=21&name=走进委员>委员建言&detail_id='+item.id)
			}else{
				this.$router.push('/secondList?id=21&name=走进委员>委员风采&detail_id='+item.id)
			}
			
		},
        //移入事件
        handleMouseEnter(index) {
            this.mengcengInd = index
        },
        handleTabr(index) {
            this.activeInd = index
            if (index == 2) {
                this.$nextTick(() => {
                    this.initSwiper()
                })
            }
        },
        handleBtn(index) {
            if (index == 1) {
                this.swiper.slidePrev();
            } else {
                this.swiper.slideNext();
            }
        },
        // 初始化轮播
        initSwiper() {
            this.swiper = new Swiper(this.$refs.swiperContainer, {
                slidesPerView: 5,
                spaceBetween: 10,
                loop: true,
                observer: true,
                speed: 1000,
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
					click:(e)=> {
						if(e.activeIndex>this.fengcaiData.length){
							let ii = (e.activeIndex % this.fengcaiData.length)-1
							if (this.fengcaiData[ii].tzurl) {
								window.open(this.fengcaiData[ii].tzurl, "_blank")
							} else {
								this.$router.push('/secondList?id=21&name=走进委员>委员风采&detail_id='+this.fengcaiData[ii].id)
							}
						}
						
					}
                }
            })
        },
    },
    
}
</script>
  
<style scoped lang="scss">
.swiperimgbox{
	cursor: pointer;
	.text_show{
		display: none !important;
	}
}
.swiperimgbox:hover{
	.text_show{
		display: flex !important;
	}
}
.weiyuan_lvzhi {
    padding: .48rem 0 .80rem 0;
    box-sizing: border-box;
    height: 6.41rem;

    .title {
        display: flex;
        align-items: center;

        .title_l {
            img {
                display: block;
                width: 2.72rem;
            }
        }

        .title_r {
            flex: 1;
            border-bottom: 0.01rem solid transparent;
            /* 先设置底边框为透明 */
            background-image: linear-gradient(to right, #0054C7, #AEBCDD);
            background-position: bottom;
            background-size: 100% 0.01rem;
            /* 渐变高度为10px */
            background-repeat: no-repeat;

            .nav_bar {
                p {
                    margin: 0 0.225rem 0.03rem 0.225rem;
                    cursor: pointer;
                }

                p.active {
                    color: #E03246;
                    border-bottom: 0.02rem solid #E03246;
                }
            }
        }
    }

    .cont {
        .weiyuanjianyan {
            ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
				flex-direction: column;
                li {
                    width: 100%;
                    margin-top: .15rem;
                    display: flex;
                    align-items: center;

                    .dian {
                        width: 10px;
                        height: 10px;
                        background: #535353;
                        border-radius: 50% 50%;
                        margin-right: .14rem;
                    }

                    p {
						width: 85%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
					p:hover{
						color: #0054C7;
						cursor: pointer;
					}
                }
            }
        }

        .weiyuanfengcai {
            position: relative;

            .swiper-button-prev,
            .swiper-button-next {
                width: .80rem;
                height: .80rem;
                background: #fff;
                position: absolute;
                top: 50%;
                margin-top: -.40rem;
                z-index: 10;
                font-size: .50rem;
                color: #3D7ED0;
                text-align: center;
                border-radius: 50%;
                line-height: .80rem;
                cursor: pointer;
            }

            .swiper-button-prev {
                left: -.40rem;
            }
            .swiper-button-prev.act,.swiper-button-next.act{
                background: #3D7ED0;
                color: #fff;
            }

            .swiper-button-next {
                right: -.40rem;
            }

            .swiper-container {
                height: 3.55rem;
                margin-top: .59rem;

                .swiper-wrapper {

                    .swiper-slide {
                        position: relative;
                        height: 100%;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }

                        .item {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: rgba(00, 00, 00, .35);
                            top: 0;
                            left: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
							height: 100%;
							padding-bottom: 5%;
                            .Mc_name {
                                height: .50rem;
                                box-sizing: border-box;
                                padding: .06rem 0 .06rem .24rem;
                                background: url('../../../assets/home/item_bg.png') no-repeat;
                                background-size: 100% 100%;
                            }

                            .Mc_text {
                                height: fit-content;
                                box-sizing: border-box;
                                padding: 0 .2rem 0 .2rem;
                                overflow: hidden;

                                p {
                                    height: 100%;
                                    overflow: hidden;
                                    text-overflow: -o-ellipsis-lastline;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>