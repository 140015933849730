<template>
    <div class="img_news">
        <div class="title">
            <img src="@/assets/home/imgNewsTitle.png" alt="">
        </div>
        <div class="cont">
            <div class="swiper-container" ref="imgNews">
                <div class="swiper-wrapper">
                    <div class="swiper-slide font19 imgboxshow" v-for="(item, index) in newData" :key="item.id"
                        @mouseenter="handleMouseEnter(index)" @mouseleave="mengcengInd = ''" @click="goxq(item)">
                        <img :src="item.slturl[0].fullurl" alt="">
                        <div class="item nameshow">
                            <div class="Mc_text font22 fontweight400" style="padding: 0 10px;">{{ item.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='swiper-button-prev' @click="handleBtn(1)"><i class="el-icon-arrow-left"></i>
            </div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <div class='swiper-button-next' @click="handleBtn(2)"><i class="el-icon-arrow-right"></i></div>
        </div>
    </div>
</template>
  
<script>
import 'swiper/swiper.scss'
import Swiper, { Autoplay } from 'swiper'
Swiper.use([Autoplay])
export default {
    data() {
        return {
            swiper: '',
            mengcengInd: '',
            newData: []
        }
    },
	mounted() {	    
		this.$post({//获取图片新闻数据
			url: '/api/news/index',
			params: {
				id:13,
				p: 1,
				size: 1000
			}
		}).then((res) => {
			res.list = res.list.filter((item) => item.slturl.length>=1)
			this.newData = res.list
			this.initSwiper()
		})
	},
    methods: {
		goxq(item){
			if(item.tzurl){
				window.open(item.tzurl,"_blank")
				return
			}
			this.$router.push('/secondList?id=19&name=政协要闻&detail_id='+item.id)
		},
        handleMouseEnter(index) {
            this.mengcengInd = index
        },
        initSwiper() {
            this.swiper = new Swiper(this.$refs.imgNews, {
                slidesPerView: 5,
                spaceBetween: 10,
                loop: true,
                observer: true,
                speed: 1000,
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    click: function (e) {
                        console.log(e);
                    }
                }
            })
        },
        handleBtn(index) {
            if (index == 1) {
                this.swiper.slidePrev();
            } else {
                this.swiper.slideNext();
            }
        },
    },
    
}
</script>
  
<style scoped lang="scss">
	.imgboxshow{
		cursor: pointer;
		.nameshow{
			display: none !important;
		}
	}
	.imgboxshow:hover{
		.nameshow{
			display: flex !important;
		}
	}
	
.img_news {
    .title {
        img {
            display: block;
            width: 9.33rem;
            height: .49rem;
            margin: .60rem auto .28rem;
        }
    }

    .cont {
        padding: .5rem .5rem;
        box-sizing: border-box;
        border: 0.01rem solid #E1E1E1;
        position: relative;

        .swiper-button-prev,
        .swiper-button-next {
            height: 2.27rem;
            line-height: 2.27rem;
            width: 0.42rem;
            background: #3D7ED0;
            position: absolute;
            top: 50%;
            margin-top: -1.135rem;
            z-index: 10;
            font-size: .50rem;
            color: #fff;
            text-align: center;
            cursor: pointer;
        }

        .swiper-button-prev {
            left: -.01rem;
        }

        .swiper-button-next {
            right: -.01rem;
        }

        .swiper-container {
            height: 2.27rem;

            .swiper-wrapper {
                .swiper-slide {
                    position: relative;
                    width: 100%;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    .item {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: rgba(00, 00, 00, .35);
                        top: 0;
                        left: 0;
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                    }
                }
            }
        }
    }




}
</style>