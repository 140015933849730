<template>
    <div class="content_group" style="padding: 0 2.6rem;">
        <div class="item">
            <titlePic :parameter="{ 'text': detial.line1.name, 'link': detial.line1.link }" />
            <ul class="font19 color-33 sethei">
                <li v-for="(item,index) in detial.line1.list" :key="item.id" @click="goxq(detial.line1.name,item)">
                    <div class="dian"></div>
                    <p class="titlename">{{item.name}}</p>
                </li>
            </ul>
        </div>
        <div class="item">
            <titlePic :parameter="{ 'text': detial.line2.name,'link': detial.line2.link }" />
            <ul class="font19 color-33 sethei">
                <li v-for="(item,index) in detial.line2.list" :key="item.id" @click="goxq(detial.line2.name,item)">
                    <div class="dian"></div>
                    <p class="titlename">{{item.name}}</p>
                </li>
            </ul>
        </div>
        <div class="item">
            <titlePic :parameter="{ 'text': detial.line3.name, 'link': detial.line3.link }" />
            <ul class="font19 color-33 sethei">
                <li v-for="(item,index) in detial.line3.list" :key="item.id" @click="goxq(detial.line3.name,item)">
                    <div class="dian"></div>
                    <p class="titlename">{{item.name}}</p>
                </li>
            </ul>
        </div>
		
        <!-- <div class="item">
            <titlePic :parameter="{ 'text': detial.line4.name,'link': detial.line4.link }" />
            <ul class="font19 color-33 sethei">
                <li v-for="(item,index) in detial.line4.list" :key="item.id" @click="goxq(detial.line4.name,item)">
                    <div class="dian"></div>
                    <p class="titlename">{{item.name}}</p>
                </li>
            </ul>
        </div> -->
        <div class="item">
            <titlePic :parameter="{ 'text': detial.line5.name, 'link': detial.line5.link }" />
            <ul class="font19 color-33 sethei">
                <li v-for="(item,index) in detial.line5.list" :key="item.id" @click="goxq(detial.line5.name,item)">
                    <div class="dian"></div>
                    <p class="titlename">{{item.name}}</p>
                </li>
            </ul>
        </div>
        <!-- <div class="item">
            <titlePic :parameter="{ 'text': detial.line6.name, 'link': detial.line6.link }" />
            <ul class="font19 color-33 sethei">
                <li v-for="(item,index) in detial.line6.list" :key="item.id" @click="goxq(detial.line6.name,item)">
                    <div class="dian"></div>
                    <p class="titlename">{{item.name}}</p>
                </li>
            </ul>
        </div> -->
    </div>
</template>
  
<script>
import titlePic from "@/components/title_pic.vue"
export default {
    components: {
        titlePic
    },
    data() {
        return {
			detial:{
				line1:{//专委会工作
					name:'',
					link:'',
					list:[]
				},
				line2:{//党派团体
					name:'',
					link:'',
					list:[]
				},
				line3:{//区县政协
					name:'',
					link:'',
					list:[]
				},
				line4:{//提案公开
					name:'',
					link:'',
					list:[]
				},
				line5:{//机关建设
					name:'',
					link:'',
					list:[]
				},
				line6:{//文史资料
					name:'',
					link:'',
					list:[]
				}
			}			
        }
    },
	created() {
		this.$post({
			url: '/api/classify/index',
			params: {
				type:1,
				p: 1,
				size: 9999
			}
		}).then((res) => {
			res.list.forEach((item,index)=>{//获取模块标题中文
				if(item.id == 3){//专委会工作
					this.detial.line1.name = item.name
					this.detial.line1.link = '/secondList?id=3&name='+item.name
					this.getlist(3,1)
				}
				if(item.id == 4){//党派团体
					this.detial.line2.name = item.name
					this.detial.line2.link = '/secondList?id=4&name='+item.name
					this.getlist(4,2)
				}
				if(item.id == 5){//区县政协
					this.detial.line3.name = item.name
					this.detial.line3.link = '/secondList?id=5&name='+item.name
					this.getlist(5,3)
				}
				if(item.id == 9){//提案公开
					this.detial.line4.name = item.name
					this.detial.line4.link = '/secondList?id=9&name='+item.name
					this.getlist(9,4)
				}
				if(item.id == 8){//机关建设
					this.detial.line5.name = item.ej[0].name
					this.detial.line5.link = '/secondList?id=26&name=机关建设>'+item.ej[0].name
					this.getlist(26,5)
				}
				if(item.id == 10){//文史资料
					this.detial.line6.name = item.name
					this.detial.line6.link = '/secondList?id=10&name='+item.name
					this.getlist(10,6)
				}
			})
		})
	},
	methods:{
		goxq(name,item){
			if(item.tzurl){
				window.open(item.tzurl,"_blank")
				return
			}
			this.$router.push('/secondList?id=19&name='+name+'&detail_id='+item.id)
		},
		getlist(id,line){
			this.$post({//获取数据
				url: '/api/news/index',
				params: {
					id:id,
					p: 1,
					size: 3
				}
			}).then((res) => {
				this.detial['line'+line].list = res.list
			})
		}
	}
}
</script>
  
<style scoped lang="scss">
	.sethei{
		min-height: 1.38rem;
		background-color: #fff;
	}
.content_group {
    margin-top: .37rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
        width: 49%;
        border: 0.01rem solid #E1E1E1;
        margin-bottom: .32rem;

        ul {
            padding: .21rem .67rem .14rem .37rem;
            box-sizing: border-box;

            li {
                display: flex;
                align-items: center;

                .dian {
                    width: .06rem;
                    height: .05rem;
                    background: #2C65AF;
                    margin-right: .21rem;
                }

                p {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
					width: 98%;
                }
            }

            li:nth-child(2) {
                margin: .14rem 0;
            }
        }
    }
}
.titlename{
	cursor: pointer;
}
.titlename:hover{
	color: #2C65AF;
}
</style>